import "./services.css";
import house from "../../../img/icons/house-03.png";
import sofa from "../../../img/icons/sofa-03.png";
import van from "../../../img/icons/van-03.png";

function services() {
  return (
    <div className="services section" id="services">
      <div className="container">
        <div className="services-text">
          <h5>SERVICES</h5>
          <h2>Our Services</h2>
          <p>
            Need a helping hand with your move? We offer a range of services to
            meet your needs:
          </p>
        </div>

        <div className="services-box">
          <div>
            <img src={house} alt="icon" className="services-box-icon"></img>
            <h3>House removals</h3>
            <p>
              Professional house removals tailored to your specific
              requirements.
            </p>
          </div>
          <div>
            <img
              src={sofa}
              alt="icon"
              className="services-box-icon green"
            ></img>
            <h3>Large item delivery</h3>
            <p>
              Safe and efficient delivery of large items, such as furniture
              house equipment.
            </p>
          </div>
          <div>
            <img src={van} alt="icon" className="services-box-icon red"></img>
            <h3>Man-with-a-van</h3>
            <p>Flexible and affordable delivery solutions for smaller loads.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default services;
