import "./reviews.css";

import pic01 from "../../../img/icons/userpic.png";
import star from "../../../img/icons/star-full.png";

function reviews() {
  return (
    <div className="reviews section">
      <div className="container">
        <div className="reviews-box">
          <div>
            <h5>REVIEWS</h5>
            <h2>Our reviews </h2>
            <p>
              Don't just take our word for it. See what our satisfied customers
              have to say.
            </p>
          </div>

          <div className="reviews-slider">
            <div>
              <div className="reviews-slider-item">
                <p>
                  "Highly recommend FSG Removals services, we found them quick
                  and effective, which helped a lot when moving to a new home."
                </p>

                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>David T.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
              <div className="reviews-slider-item">
                <p>
                  "Flash Services made our recent move incredibly smooth. Their
                  team was professional, efficient, and took great care of our
                  belongings. Highly recommend!"
                </p>

                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>Beth S.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
              <div className="reviews-slider-item">
                <p>
                  "Needed a large item delivered quickly? Flash Services was the
                  answer. They were punctual, courteous, and handled the
                  delivery with care. A+ service!"
                </p>
                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>Alice L.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
              <div className="reviews-slider-item">
                <p>
                  "I've used Flash Services for multiple moves, and they
                  consistently deliver outstanding service. Their team is always
                  reliable, efficient, and goes the extra mile."
                </p>
                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>Robert G.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
              <div className="reviews-slider-item">
                <p>
                  "Flash Services has consistently exceeded our expectations.
                  They've handled multiple moves for us, and each time has been
                  a stress-free experience."
                </p>
                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>Sophie B.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="reviews-slider-item">
                <p>
                  "Highly recommend FSG Removals services, we found them quick
                  and effective, which helped a lot when moving to a new home."
                </p>

                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>David T.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
              <div className="reviews-slider-item">
                <p>
                  "Flash Services made our recent move incredibly smooth. Their
                  team was professional, efficient, and took great care of our
                  belongings. Highly recommend!"
                </p>

                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>Beth S.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
              <div className="reviews-slider-item">
                <p>
                  "Needed a large item delivered quickly? Flash Services was the
                  answer. They were punctual, courteous, and handled the
                  delivery with care. A+ service!"
                </p>
                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>Alice L.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
              <div className="reviews-slider-item">
                <p>
                  "I've used Flash Services for multiple moves, and they
                  consistently deliver outstanding service. Their team is always
                  reliable, efficient, and goes the extra mile."
                </p>
                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>Robert G.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
              <div className="reviews-slider-item">
                <p>
                  "Flash Services has consistently exceeded our expectations.
                  They've handled multiple moves for us, and each time has been
                  a stress-free experience."
                </p>
                <div className="reviews-icons">
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                  <img src={star} alt="star icon"></img>
                </div>

                <div>
                  <img src={pic01} alt="user pic"></img>
                  <div>
                    <p>
                      <span>Sophie B.</span>
                    </p>
                    <p>Cardiff</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default reviews;
