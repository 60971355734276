import "./brands.css";
import apex from "../../../img/cont/apex-motors.webp";
import flash from "../../../img/cont/flash-auto.webp";
import deals from "../../../img/cont/only-deals.png";
import leafy from "../../../img/cont/leafy-care.png";

function brands() {
  return (
    <div className="brands section" id="brands">
      <div className="container">
        <div className="brands-text">
          <h5>OTHER BRANDS </h5>
          <h2>Our other brands</h2>
          <p>We're proud to be part of a group of local businesses:</p>
        </div>

        <div className="brands-box">
          <div>
            <img src={apex} alt="icon"></img>
            <div>
              <h3> Apex Motors Cardiff</h3>
              <p>Your local car dealership and servicing.</p>
              <a className="link" href="https://www.apexmotorscardiff.co.uk/">
                VISIT PAGE
              </a>
            </div>
          </div>
          <div>
            <img src={flash} alt="icon"></img>
            <div>
              <h3> Flash Auto Parts</h3>
              <p>Online store for premium car components.</p>
              <a
                className="link"
                href="https://www.ebay.co.uk/str/flashautoparts"
              >
                VISIT PAGE
              </a>
            </div>
          </div>

          <div>
            <img src={leafy} alt="icon"></img>
            <div>
              <h3> Leafy Care Ltd</h3>
              <p>Personalised home care agency.</p>
              <a className="link" href="https://leafycare.co.uk/">
                VISIT PAGE
              </a>
            </div>
          </div>
          <div>
            <img src={deals} alt="icon"></img>
            <div>
              <h3> OnlyDealsUK</h3>
              <p>Daily essentials, life hacks, trending products.</p>
              <a className="link" href="https://www.ebay.co.uk/str/onlydealsuk">
                VISIT PAGE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default brands;
