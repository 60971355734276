import "./why.css";

import pic01 from "../../../img/cont/9.webp";

function why() {
  return (
    <div className="why section">
      <div className="container">
        <div className="why-box">
          <img src={pic01} alt="picture"></img>

          <div>
            <h5>WHY US?</h5>
            <h2>Why choose us?</h2>
            <p>
              Looking for a reliable and affordable removal and delivery
              service? Discover what sets us apart from the competition.
            </p>

            <ul className="why-list">
              <li>
                <p>
                  <span>Experienced Team:</span> Our skilled movers are trained
                  to handle all types of removals.
                </p>
              </li>
              <li>
                <p>
                  <span>Reliable Service:</span> We pride ourselves on
                  punctuality and commitment.
                </p>
              </li>
              <li>
                <p>
                  <span>Competitive Pricing:</span> Get a free quote and
                  discover our affordable rates.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default why;
