import "./navbar.css";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

import logo from "../../img/logo/favicon.svg";
import menu from "../../img/icons/menu.svg";

import facebook from "../../img/icons/facebook.svg";
import instagram from "../../img/icons/instagram.svg";
import linkedin from "../../img/icons/linkedin.svg";
import yelp from "../../img/icons/yelp.svg";

function Navbar() {
  const location = useLocation();

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEffect(() => {
    const openNavbar = (event) => {
      if (event.target.tagName !== "BUTTON") {
        setIsNavbarOpen(false);
      }
    };

    document.addEventListener("click", openNavbar);

    return () => {
      document.removeEventListener("click", openNavbar);
    };
  }, []);

  return (
    <header className="navbar">
      <div className="navbar-box">
        <div className="navbar-logo">
          <img src={logo} alt="logo"></img>
          <p>FLASH SERVICE GROUP</p>
        </div>

        <ul className="navbar-links">
          <li>
            <Link
              to="/"
              className={location.pathname === "/" ? "active" : ""}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              HOME
            </Link>
          </li>

          <li>
            <a href="#services">SERVICES</a>
          </li>

          <li>
            <a href="#about">ABOUT</a>
          </li>

          <li>
            <a href="#brands"> OTHER BRANDS</a>
          </li>

          <li>
            <a href="#contact">CONTACT</a>
          </li>
        </ul>

        <ul className="socials">
          <li></li>
          <li>
            <a href="">
              <img src={facebook} alt="facebook"></img>
            </a>
          </li>
          <li>
            <a href="">
              <img src={instagram} alt="instagram"></img>
            </a>
          </li>
          <li>
            <a href="">
              <img src={linkedin} alt="linkedin"></img>
            </a>
          </li>
          <li>
            <a href="">
              <img src={yelp} alt="yelp"></img>
            </a>
          </li>
        </ul>

        <button
          className="navbar-mobile-button"
          onClick={() => setIsNavbarOpen(true)}
        ></button>
      </div>

      <div
        className="navbar-mobile"
        style={{
          transform: isNavbarOpen
            ? "translateY(+3.6rem)"
            : "translateY(-12rem)",
          transition: "300ms",
        }}
      >
        <ul className="navbar-mobile-links">
          <li>
            <Link
              to="/"
              className={location.pathname === "/" ? "active" : ""}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              HOME
            </Link>
          </li>

          <li>
            <a href="#services">SERVICES</a>
          </li>

          <li>
            <a href="#about">ABOUT</a>
          </li>

          <li>
            <a href="#brands"> OTHER BRANDS</a>
          </li>

          <li>
            <a href="#contact">CONTACT</a>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Navbar;
