import "./stats.css";

function stats() {
  return (
    <div className="stats section">
      <div className="container">
        <div className="stats-box">
          <div>
            <h2>
              100<span>+</span>
            </h2>
            <p>Number of moves completed</p>
          </div>
          <div>
            <h2>
              5<span>/</span>5
            </h2>
            <p>Customer satisfaction rating</p>
          </div>
          <div>
            <h2>
              10<span>+</span>
            </h2>
            <p>Years of experience in trade</p>
          </div>
          <div>
            <h2>FREE</h2>
            <p>
              Free quote available <a href="#hero">here</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default stats;
