import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./components/core/navbar";

import Home from "./components/pages/main/index";

import Footer from "./components/core/footer";

function App() {
  return (
    <BrowserRouter>
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
