import "./hero.css";

import van from "../../../img/cont/van-4.webp";

function hero() {
  return (
    <div className="hero section" id="hero">
      <div className="container">
        <div className="hero-box">
          <div className="hero-box-text">
            <h1>
              <span>Removals</span> and <span>Delivery</span> Services in
              Cardiff.
            </h1>
            <p>Your Reliable Partner for Seamless Moves.</p>
          </div>

          <img src={van} alt="van" className="hero-van"></img>

          <div className="quote-box">
            <h3>Plan Your Move with Ease. Get a FREE quote.</h3>

            <form
              action="https://formsubmit.co/flashservicegroup@outlook.com"
              method="POST"
              className="quote-form"
            >
              <div>
                <label for="from"> Moving from: </label>
                <input
                  type="text"
                  id="from"
                  name="from"
                  placeholder="moving from postcode A"
                />
              </div>

              <div>
                <label for="to"> Moving to: </label>
                <input
                  type="text"
                  id="to"
                  name="to"
                  placeholder="moving to postcode B"
                />
              </div>
              <div>
                <label for="size"> Load size: </label>
                <input
                  type="text"
                  id="size"
                  name="size"
                  placeholder="eg.: 2 sofas or 3 big boxes"
                />
              </div>
              <div>
                <label for="contact"> Contact info: </label>
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  placeholder="Your email or number"
                />
              </div>

              <button type="submit">GET A QUOTE</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default hero;
