import "./about.css";

import pic01 from "../../../img/cont/3.webp";

import facebook from "../../../img/icons/facebook.svg";
import instagram from "../../../img/icons/instagram.svg";
import linkedin from "../../../img/icons/linkedin.svg";
import yelp from "../../../img/icons/yelp.svg";

function about() {
  return (
    <div className="about section" id="about">
      <div className="container">
        <div className="about-box">
          <div>
            <h5>ABOUT US</h5>
            <h2>About Us</h2>
            <p>
              We are a local, family-run business dedicated to providing
              top-notch removal and delivery services in Cardiff and the
              surrounding areas.
            </p>
            <p>
              With years of experience, we've perfected the trade, ensuring a
              stress-free and efficient experience for our customers.
            </p>

            <ul className="socials">
              <li>
                <h4>Find us on:</h4>
              </li>
              <li>
                <a href="">
                  <img src={facebook} alt="facebook"></img>
                </a>
              </li>
              <li>
                <a href="">
                  <img src={instagram} alt="instagram"></img>
                </a>
              </li>
              <li>
                <a href="">
                  <img src={linkedin} alt="linkedin"></img>
                </a>
              </li>
              <li>
                <a href="">
                  <img src={yelp} alt="yelp"></img>
                </a>
              </li>
            </ul>
          </div>

          <img src={pic01} alt="picture"></img>
        </div>
      </div>
    </div>
  );
}

export default about;
