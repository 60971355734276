import "./contact.css";

import pic01 from "../../../img/cont/4.webp";

import tick from "../../../img/icons/tick.svg";

function contact() {
  return (
    <div className="contact section" id="contact">
      <div className="container">
        <div className="contact-box">
          <img src={pic01} alt="picture"></img>

          <div>
            <h5>CONTACT </h5>
            <h2>Contact Us</h2>
            <p>
              Ready to get started? Contact us today for a free quote and to
              discuss your specific needs.
            </p>

            <ul className="contact-info">
              <li>
                <img src={tick} alt="picture"></img>
                <p>(+44)7368 638281</p>
              </li>
              <li>
                <img src={tick} alt="picture"></img>
                <p>flashservicegroup@outlook.com</p>
              </li>
              <li>
                <img src={tick} alt="picture"></img>
                <div>
                  <p>Global Link, Dunleavy Drive,</p>
                  <p>Cardiff, Wales,</p>
                  <p>CF11 0SN</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default contact;
