import "./how.css";

import chat from "../../../img/icons/chat-01.png";
import pack from "../../../img/icons/packing-01.png";
import van from "../../../img/icons/van-04.png";

function how() {
  return (
    <div className="how section">
      <div className="container">
        <div className="how-text">
          <h5>HOW IT WORKS?</h5>
          <h2>How it works?</h2>
          <p>
            Curious about our process? Learn how we simplify your move from
            start to finish.
          </p>
        </div>
        <div className="how-box">
          <div>
            <div className="how-box-icon">
              <img src={chat} alt="icon"></img>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 100 10"
              >
                <g
                  stroke-width="0.5"
                  stroke="var(--clr-4)"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-dasharray="2 2"
                >
                  <line
                    x1="0"
                    y1="5"
                    x2="95"
                    y2="5"
                    marker-end="url(#SvgLineYellow)"
                  ></line>
                </g>
                <defs>
                  <marker
                    markerWidth="5"
                    markerHeight="5"
                    refX="2.5"
                    refY="2.5"
                    viewBox="0 0 5 5"
                    orient="auto"
                    id="SvgLineYellow"
                  >
                    <polygon
                      points="0,5 0,0 5,2.5"
                      fill="var(--clr-4)"
                    ></polygon>
                  </marker>
                </defs>
              </svg>
            </div>

            <h3>1. Get in touch</h3>
            <p>Contact us for a free quote and discuss your specific needs.</p>
          </div>
          <div>
            <div className="how-box-icon">
              <img src={pack} alt="icon"></img>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 100 10"
              >
                <g
                  stroke-width="0.5"
                  stroke="var(--clr-5)"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-dasharray="2 2"
                >
                  <line
                    x1="0"
                    y1="5"
                    x2="95"
                    y2="5"
                    marker-end="url(#SvgLineGreen)"
                  ></line>
                </g>
                <defs>
                  <marker
                    markerWidth="5"
                    markerHeight="5"
                    refX="2.5"
                    refY="2.5"
                    viewBox="0 0 5 5"
                    orient="auto"
                    id="SvgLineGreen"
                  >
                    <polygon
                      points="0,5 0,0 5,2.5"
                      fill="var(--clr-5)"
                    ></polygon>
                  </marker>
                </defs>
              </svg>
            </div>

            <h3>2. Packing</h3>
            <p>Our team can assist with packing your belongings securely.</p>
          </div>
          <div>
            <div className="how-box-icon">
              <img src={van} alt="icon"></img>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 100 10"
              >
                <g
                  stroke-width="0.5"
                  stroke="var(--clr-6)"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-dasharray="2 2"
                >
                  <line
                    x1="0"
                    y1="5"
                    x2="95"
                    y2="5"
                    marker-end="url(#SvgLineRed)"
                  ></line>
                </g>
                <defs>
                  <marker
                    markerWidth="5"
                    markerHeight="5"
                    refX="2.5"
                    refY="2.5"
                    viewBox="0 0 5 5"
                    orient="auto"
                    id="SvgLineRed"
                  >
                    <polygon
                      points="0,5 0,0 5,2.5"
                      fill="var(--clr-6)"
                    ></polygon>
                  </marker>
                </defs>
              </svg>
            </div>

            <h3>3. Delivery</h3>
            <p>
              We'll transport your items safely and efficiently to your new
              location.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default how;
