import Hero from "./hero";
import Services from "./services";
import About from "./about";
import Stats from "./stats";
import Why from "./why";
import Banner from "./banner";
import How from "./how";
import Reviews from "./reviews";
import Contact from "./contact";

import Brands from "./brands";

function main() {
  return (
    <div>
      <Hero />
      <Services />
      <About />
      <Stats />
      <Why />
      <Banner />
      <How />
      <Reviews />
      <Contact />

      <Brands />
    </div>
  );
}

export default main;
