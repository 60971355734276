import "./banner.css";

function banner() {
  return (
    <div className="banner section">
      <div className="container">
        <div className="banner-box">
          <h2>We service Cardiff </h2>
          <h2>and ALL surrounding areas.</h2>
        </div>
      </div>
    </div>
  );
}

export default banner;
