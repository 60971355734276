import "./footer.css";

function footer() {
  return (
    <footer className="footer">
      <div className="copyright">
        <p>Copyright @2024 Flash Service Group. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default footer;
